@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sofia+Sans+Extra+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+Toto:wght@400;500;600;700&display=swap");
@font-face {
  font-family: Proxima;
  src: url("../../fonts/ProximaNovaExCn-Regular.ttf") format("truetype");
  font-weight: bold;
}

$cl-white: #ffffff;
$cl-black: #000000;
$cl-gray: #696969;
$cl-green: #008060;
$cl-orange: #ff7a59;

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  @include font(1.4rem, 400, 1.5);
  font-family: "Helvetica Neue", sans-serif;
}

img {
  display: block;
  max-width: 100%;
  object-fit: cover;
}

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
}

input,
select {
  width: 100%;
  line-height: normal;
}

//==============================
.wrapper {
  max-width: 100%;
  margin: 0 auto;
}
.wrapper-page {
  // min-height: calc(100vh - 26rem);
  min-height: calc(100vh - 80px - 59.72px);
}
.container {
  margin: 0 auto;
  padding: 0;
  @include mq_max_576 {
    max-width: 576px;
  }
  @include mq_min_576 {
    max-width: 540px;
  }
  @include mq_min_768 {
    max-width: 720px;
  }
  @include mq_min_1024 {
    max-width: 960px;
  }
  @include mq_min_1200 {
    max-width: 1172px;
  }
}
.container-fluid {
  max-width: 100%;
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.wrap {
  flex-wrap: wrap;
}
.nowrap {
  flex-wrap: nowrap;
}
.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mt-50 {
  margin-top: 50px;
}
.mt-45 {
  margin-top: 45px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-50 {
  @include mq_max_576 {
    margin-bottom: 50px !important;
  }
}
.bold {
  font-weight: bold;
}
.pt-25 {
  padding-top: 25px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-50 {
  padding-top: 50%;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-80 {
  padding-bottom: 80px;
}
.w-100 {
  width: 100%;
}
.one-grid {
  grid-template-columns: 1fr !important;
}
.three-grid {
  grid-template-columns: 1fr 1fr 1fr !important;
  @include mq_max_576 {
    grid-template-columns: 1fr !important;
  }
}

//==============================
.btn {
  // max-width: 12rem;
  // min-width: 12rem;
  max-width: 16rem;
  min-width: 16rem;
  height: 4rem;
  padding: 0.4rem 2.5rem;
  font-weight: 500;
  background-color: #fff;
  border: 1px solid #696969;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  &:disabled {
    border-color: rgba(16, 16, 16, 0.3);
    cursor: no-drop;
  }
}

.sp {
  display: block;
  @include mq_min_768 {
    display: none !important;
  }
}

.pc {
  display: none;
  @include mq_min_768 {
    display: block !important;
  }
}

.pc-flex {
  display: none;
  @include mq_min_768 {
    display: flex !important;
  }
}

.btn_main {
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: 0.2s ease all;
}

.btn_design,
.btn_resume,
.btn_accept {
  width: 14.3rem;
  height: 3rem;
  font-size: 1.4rem;
  font-weight: bold;
  @include mq_min_1024 {
    width: 27.8rem;
    height: 5.8rem;
    font-size: 2.6rem;
  }
}
.btn_design {
  background: $cl-black;
  color: $cl-white;
  margin: 0 0 25px 0;
  @include mq_min_768 {
    margin-right: 4rem;
  }
}
.btn_resume {
  background: $cl-white;
  color: $cl-black;
}
.btn_accept {
  background: $cl-black;
  color: $cl-white;
}
.btn_show {
  width: 117px;
  height: 31px;
  border: 1px solid #dbdbdb;
  background: transparent;
  @include mq_max_576 {
    display: none;
  }
}
.btn_done,
.btn_download {
  width: 156px;
  height: 41px;
  font-size: 1.4rem;
  margin-top: 28px;
  @include mq_max_576 {
    margin-top: 0;
  }
  &.btn_done {
    background: $cl-black;
    color: $cl-white;
    margin-right: 10px;
  }
  &.btn_download {
    color: $cl-black;
    background: $cl-white;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-left: 5px;
    }
  }
}

.btn-dowload-image {
  color: #797979 !important;
  background-color: #fff !important;
  border-color: #797979 !important;
  box-shadow: none;
}

.btn-save-image {
  width: 156px;
  height: 41px;
  margin: 0;
  font-size: 1.4rem;
  color: $cl-white;
  background-color: #3d3d3d !important;
}

.click-btn-save-image {
  width: 156px;
  height: 41px;
  margin: 0;
  font-size: 1.4rem;
  background: #3d3d3d !important;
  color: $cl-white;
}

.btn-show-option {
  display: flex;
  justify-content: center;
  align-items: center;
}

.block_button_main {
  // margin-top: 3rem;
  margin: 0 auto 2rem;
  @include mq_min_1024 {
    margin: 2rem auto 8rem;
  }
  @include flexbox(flex, center, center, wrap);
  .btn_download {
    // margin-top: 1.5rem;
    @include mq_min_768 {
      margin-top: 2.8rem;
    }
  }
}

.display-btn {
  display: none;
  @include mq_max_576 {
    display: block;
    background: $cl-white;
  }
}

.border-30 {
  border-radius: 30px !important;
}

.pb-50 {
  padding-bottom: 5rem;
}
.pb-10 {
  padding-bottom: 10rem;
}

.set-block {
  display: block;
}
.set-none {
  display: none;
}

// .show {
//   opacity: 1;
//   visibility: visible;
// }
.hidden {
  opacity: 0;
  visibility: hidden;
}
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(67, 67, 67, 0.5);
  @include flexbox(flex, center, center, nowrap);
  z-index: 999999;
  user-select: none;
  .ant-spin-dot-item {
    background-color: #26ff00 !important;
  }
}
.modal-dowload-image {
  max-width: 32.8rem !important;
  @include mq_min_768 {
    max-width: 50rem !important;
  }
  .ant-modal-content {
    &::before {
      position: static !important;
    }
  }
  .ant-spin-dot-item {
    background-color: #26ff00 !important;
  }
}

.flex-box-center {
  @include flexbox(flex, center, center, nowrap);
}

.form-upload-style .ant-upload-select {
  width: 4rem !important;
  height: 4rem !important;
  margin: 0 !important;
}

.ant-collapse-header-text {
  margin-left: -2.4rem;
}
.ant-select-selection-item {
  margin-right: -2.4rem;
}

.min-height-layout {
  min-height: calc(100vh - 55.97px);
}

.fix-pagination {
  .ant-select-selection-item {
    margin-right: 0;
  }
}

.modal-duplicate-product {
  .ant-space-item {
    width: 100%;
  }
  .ant-modal-content::before {
    position: static !important;
  }
}
