.payment {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  .payment-wrap {
    padding: 80px 20px 20px 20px;
    // border: 1px solid #dcdcdc;
    border-radius: 8px;
    background-color: #fff;
    @media screen and (min-width: 768px) {
      width: 550px;
      margin: 50px auto;
      padding: 30px 30px 10px;
    }
    h3 {
      margin-bottom: 30px;
      font-size: 20px;
      // font-weight: bold;
      color: rgba(0, 0, 0, 0.88);
    }
    label {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.88);
    }
    .ant-form-item-label {
      text-align: left;
    }
    input {
      width: 100%;
      @media screen and (min-width: 768px) {
        width: 320px;
      }
    }
    .payment-method-title {
      margin-bottom: 20px;
      font-size: 18px;
      // font-weight: bold;
      color: rgba(0, 0, 0, 0.88);
    }
  }
}
